import { NextRouter, useRouter } from 'next/dist/client/router'
import { useCallback, useMemo } from 'react'
import { DEFAULT_LOCALE } from 'src/@legacy/utilities/Constant'
import { UrlObject } from 'url'
import { COOKIES_NAME_LANGUAGE, getCookie } from '../utillities/Cookies'
import { getIsServer } from '../utillities/getIsServer'
// import * as qs from 'query-string'
import { getLocaleInRewriteContext, getOverideLocale } from '../utillities/TranslationUtils'
import { translateRouterHref } from './useTranslateLink'
import queryString from 'query-string'
declare type Url = UrlObject | string
interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
  usePush?: boolean
  ignoreTranslation?: boolean
  keepUTM?: boolean
}
const mergeUTLQuery = (href, query) => {
  const [url, hquery] = href.split('?')
  const fullQuery = { ...queryString.parse(hquery), ...query }
  return `${url}${Object.values(fullQuery).length > 0 ? `?${queryString.stringify(fullQuery)}` : ``}`
}
const useAppRouter = () => {
  const router = useRouter()
  const locale = useMemo(() => {
    const overideLocale = getOverideLocale(router.asPath, router.locale)
    const strickLocale = router.locale === 'eng' ? 'en' : 'vi'
    return overideLocale || (getIsServer() ? strickLocale : getCookie(COOKIES_NAME_LANGUAGE)) || strickLocale
  }, [router])
  const push = useCallback(
    (url: Url, as?: Url, options?: TransitionOptions) => {
      const { locale: pushLocale, ...nonLocaleOptions } = options || ({} as TransitionOptions)
      const currentAsContext = translateRouterHref((as as string) || (url as string), pushLocale || locale)
      const urlWithUTM = mergeUTLQuery(url, queryString.parse(router.asPath.split('?')[1]))
      // if (nonLocaleOptions?.mergeQuery) {
      //   const finalAs = currentAsContext || as
      //   const [pathStr, searchString] = finalAs.toString().split('?')
      //   const currentQuery = qs.parse(searchString)
      //   const query = { ...currentQuery, ...nonLocaleOptions.mergeQuery }
      //   return router.push({ pathname: url.toString(), query }, { pathname: pathStr, query }, { ...nonLocaleOptions })
      // }

      if (!options?.usePush) {
        window.location.href = options?.keepUTM
          ? mergeUTLQuery(currentAsContext, router.query)
          : currentAsContext || (as ? as.toString() : url ? url.toString() : '')
        return Promise.resolve(true)
      }
      if (options?.ignoreTranslation) {
        return router.push(options?.keepUTM ? urlWithUTM : url, as, nonLocaleOptions)
      }
      return router.push(options?.keepUTM ? urlWithUTM : url, currentAsContext || as, nonLocaleOptions)
    },

    [router, locale]
  )
  const appRouter = useMemo<NextRouter & { push: (url: Url, as?: Url, options?: TransitionOptions) => Promise<boolean> }>(
    () => ({ ...router, locale, push }),
    [router]
  )
  return appRouter
}
export default useAppRouter
