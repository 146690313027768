import { match, compile } from 'path-to-regexp'
import config from '../../../../config'
import { getJobQueryFromSEOPathNameV2, toSEOPathNameV2 } from '../utillities/SEOUtilities'
import { useTranslation } from './useTranslation'

const LinkResolverHandleMap = {
  JobSearchSlugName: (pathName, lang) => {
    const { search, locationId, categoryId } = getJobQueryFromSEOPathNameV2(pathName)
    return toSEOPathNameV2({ search, locationId, categoryId }, lang)
  }
}

export const translateRouterHref = (href: string, lang: string) => {
  const [pathName, searchQuery] = href ? href?.split('?') : []
  const translateRouter = config.routing.translates.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    if (translateRouter.linkResolvers) {
      const rotuerHandle = translateRouter.linkResolvers.find((item) => {
        return item.notMatch && !pathName.match(new RegExp(item.notMatch))
      })
      if (rotuerHandle) {
        const resolvedHref = LinkResolverHandleMap[rotuerHandle.handler](pathName, lang)
        const matcherSource =
          match<any>(translateRouter.default)(resolvedHref) ||
          (translateRouter.en && match<any>(translateRouter.en)(resolvedHref)) ||
          (translateRouter.vi && match<any>(translateRouter.vi)(resolvedHref))
        if (!translateRouter[lang]) {
          return resolvedHref + (searchQuery ? `?${searchQuery}` : ``)
        }
        //@ts-ignore
        const translateHref = compile(translateRouter[lang])(matcherSource.params)
        return translateHref + (searchQuery ? `?${searchQuery}` : ``)
      }
    }
    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))

    if (!translateRouter[lang]) {
      return href
    }

    //@ts-ignore
    const translateHref = compile(translateRouter[lang])(matcherSource.params)
    return translateHref + (searchQuery ? `?${searchQuery}` : ``)
  }
  return href
}

export const useTranslateLink = () => {
  const { currentLanguage } = useTranslation()
  return (href: string) => {
    const url = new URL(href)
    url.pathname = translateRouterHref(url.pathname, currentLanguage)
    return url.toString()
  }
}
